import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Article/style.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["CodeLineHandlers"] */ "/vercel/path0/src/components/CodeLineHandlers/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/PageLink/style.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/SiteFooter/style.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/SiteHeader/style.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/svg/fa-6.6.0/brands/github.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/svg/fa-6.6.0/brands/x-twitter.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/svg/rss.svg");
